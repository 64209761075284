module.exports = [{
      plugin: require('/Users/pascalprecht/projects/thoughtram/blog/gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('/Users/pascalprecht/projects/thoughtram/blog/gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-51360648-2"},
    },{
      plugin: require('/Users/pascalprecht/projects/thoughtram/blog/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
