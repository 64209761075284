// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/Users/pascalprecht/projects/thoughtram/blog/gatsby/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("/Users/pascalprecht/projects/thoughtram/blog/gatsby/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("/Users/pascalprecht/projects/thoughtram/blog/gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-js": () => import("/Users/pascalprecht/projects/thoughtram/blog/gatsby/src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-confirmation-thank-you-js": () => import("/Users/pascalprecht/projects/thoughtram/blog/gatsby/src/pages/confirmation-thank-you.js" /* webpackChunkName: "component---src-pages-confirmation-thank-you-js" */),
  "component---src-pages-finish-newsletter-subscription-js": () => import("/Users/pascalprecht/projects/thoughtram/blog/gatsby/src/pages/finish-newsletter-subscription.js" /* webpackChunkName: "component---src-pages-finish-newsletter-subscription-js" */),
  "component---src-pages-index-js": () => import("/Users/pascalprecht/projects/thoughtram/blog/gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

